.project-section {
    padding: 50px 0 40px;
    background-image: url("../../../src/assets/Projects/bg-21.png");
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: -1;
  }
  .project-section::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.85;
    background-color: #1f2235;
  }
  .footer-image {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: -110px;
  }
  .footer-image img {
    max-width: 100%;
    height: 130px;
  }
  
  .project-section .project-item {
    background-color: white;
    padding: 30px;
    border-radius: 50px 50px 50px 0px;
  }
  
  .project-section .project-item p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 0 10px;
    font-style: italic;
  }
  .project-section .project-item p .fa-quote-left {
    margin-right: 5px;
    color: tomato;
  }
  .project-section .project-item p .fa-quote-right {
    margin-left: 5px;
    color: tomato;
  }
  
  .project-section .project-comment .stars {
    margin: 0 0 20px;
  }
  .project-section .project-comment .stars li {
    display: inline-block;
    margin: 0 1px;
  }
  .project-section .project-comment .stars li i {
    font-size: 13px;
    color: tomato;
  }
  .project-section .project-info {
    position: relative;
    padding-left: 80px;
    padding-top: 5px;
    min-height: 60px;
  }
  .project-section .project-info img {
    height: 70px;
    width: 70px;
    border: 2px solid transparent;
    border-color: black;
    left: 0;
    top: 0;
    border-radius: 50%;
    position: absolute;
  }
  .project-section .project-info h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 2px;
  }
  .project-section .project-info p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
  }
  