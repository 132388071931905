.about-me-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
  
  }
  .about-me-parent {
    max-width: 1000px;
    width: 70%;
  }
  .about-me-card {
    display: flex;
    width: 100%;
    box-shadow: 0 0px 20px -2px #1f2235;
    border-radius: 0px 30px 30px 5px;  
  }
  .about-me-profile {
    width: 50%;
    background-image: url("../.././assets/AboutMe/me.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .about-me-details {
    width: 48%;
    text-align: justify;
    padding: 30px;
  }
  .about-me-description {
    font-size: 12px;
  }
  .about-me-highlights {
    margin: 80px 0;
  }
  .highlght-heading {
    font-family: "Montserrat Medium";
    font-size: 14px;
    margin: 0 0 5px 0;
  }
  .highlight {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 12px;
  }
  .highlight-blob {
    height: 12px;
    width: 12px;
    margin: 0 10px 0 0;
    color: var(--orange);
    border-radius: 25%;
  }
  .about-me-options .highlighted-btn {
    margin: 0 0 0 30px;
  }
  .about-me-options .highlighted-btn:hover{
    margin: 0 0 0 28px;
    background-color: rgb(4, 0, 241);
    color: var(--white);
}
  /*IPAD PRO RESPONSIVENESS*/
  @media only screen and (max-width: 1110px) {
    .about-me-parent {
      width: 88%;
    }
  }
  @media only screen and (max-width: 898px) {
    .about-me-profile {
      display: none;
    }
    .about-me-details {
      width: 100%;
    }
  }
  @media only screen and (max-width: 466px) {
    .about-me-options {
      display: flex;
      flex-direction: column;
    }
    .about-me-options .highlighted-btn {
      margin: 24px 0 0 0 !important;
    }
    .about-me-options button {
      width: 100%;
    }
  }
  